import React, { useEffect, useState } from "react";
import { Button, Modal, Form, message } from "antd";
import usePost from "../../../hooks/usePost";
import usePatch from "../../../hooks/usePatch";
import GradeDivisionTable from "../../tables/grade-division-table/GradeDivisionTable";
import AddGradeDivisionForm from "../../forms/grade-division-forms/AddGradeDivisionForm";
import EditGradeDivisionForm from "../../forms/grade-division-forms/EditGradeDivisionForm";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";

const GradeDivisions = () => {
  // State variables for fetching grade divisions
  const {
    gradeDivisions,
    fetchGradeDivisionLoading,
    fetchGradeDivisionError,
    fetchGradeDivisions,
  } = useFetchGradeDivisions();
  // State variables for adding/updating grade divisions
  const [gradeDivisionRes, setGradeDivisionRes] = useState(null);
  const [addGradeDivisionLoading, setAddGradeDivisionLoading] = useState(false);
  const [addGradeDivisionError, setAddGradeDivisionError] = useState(null);

  const [updateGradeDivisionRes, setUpdateGradeDivisionRes] = useState(null);
  const [updateGradeDivisionLoading, setUpdateGradeDivisionLoading] =
    useState(false);
  const [updateGradeDivisionError, setUpdateGradeDivisionError] =
    useState(null);

  // Modals and form handling
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [selectedGradeDivision, setSelectedGradeDivision] = useState(null);
  const [form] = Form.useForm();

  // Add grade division using the usePost hook
  const { post: addGradeDivision, reset: resetAddGradeDivision } = usePost(
    "api/gradeDivisions",
    {
      setLoading: setAddGradeDivisionLoading,
      setError: setAddGradeDivisionError,
      setData: setGradeDivisionRes,
    }
  );

  // Update grade division using the usePost hook
  const { patch: updateGradeDivision, reset: resetUpdateGradeDivision } =
    usePatch("api/gradeDivisions", {
      setLoading: setUpdateGradeDivisionLoading,
      setError: setUpdateGradeDivisionError,
      setData: setUpdateGradeDivisionRes,
    });

  useEffect(() => {
    fetchGradeDivisions();
  }, []);

  // Handle response for adding or updating grade divisions
  useEffect(() => {
    if (gradeDivisionRes?.message) {
      message[gradeDivisionRes.status || "success"](gradeDivisionRes.message);
    }

    if (gradeDivisionRes?.status === "success") {
      setIsAddModalVisible(false);
      setIsEditModalVisible(false);
      form.resetFields();
      resetAddGradeDivision();
      resetUpdateGradeDivision();
      fetchGradeDivisions();
    }
  }, [gradeDivisionRes]);

  useEffect(() => {
    if (updateGradeDivisionRes?.message) {
      message[updateGradeDivisionRes.status || "success"](
        updateGradeDivisionRes.message
      );
    }

    if (updateGradeDivisionRes?.status === "success") {
      setIsAddModalVisible(false);
      setIsEditModalVisible(false);
      form.resetFields();
      resetAddGradeDivision();
      resetUpdateGradeDivision();
      fetchGradeDivisions();
    }
  }, [updateGradeDivisionRes]);

  // Handle error messages
  useEffect(() => {
    if (addGradeDivisionError?.message) {
      message[addGradeDivisionError.status || "error"](
        addGradeDivisionError.message
      );
      resetAddGradeDivision();
    }

    if (updateGradeDivisionError?.message) {
      message[updateGradeDivisionError.status || "error"](
        updateGradeDivisionError.message
      );
      resetUpdateGradeDivision();
    }
  }, [addGradeDivisionError, updateGradeDivisionError]);

  const handleEdit = (record) => {
    console.log(record);
    setSelectedGradeDivision(record);
    setIsEditModalVisible(true);
  };

  const handleAdd = () => {
    setIsAddModalVisible(true);
  };

  return (
    <div>
      <h1>Grade Divisions</h1>
      <div style={{ float: "right", marginBottom: 10 }}>
        <Button type="primary" onClick={handleAdd}>
          Add Grade Division
        </Button>
      </div>

      <GradeDivisionTable
        gradeDivisions={gradeDivisions}
        fetchGradeDivisionLoading={fetchGradeDivisionLoading}
        onEdit={handleEdit}
      />

      <Modal
        title="Edit Grade Division"
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <EditGradeDivisionForm
          gradeDivision={selectedGradeDivision}
          onUpdate={updateGradeDivision}
          form={form}
          loading={updateGradeDivisionLoading}
        />
      </Modal>

      <Modal
        title="Add New Grade Division"
        open={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
      >
        <AddGradeDivisionForm
          onAdd={addGradeDivision}
          onClose={() => setIsAddModalVisible(false)}
          form={form}
          loading={addGradeDivisionLoading}
        />
      </Modal>
    </div>
  );
};

export default GradeDivisions;
