import React from "react";
import { Table, Button } from "antd";

const GradeDivisionTable = ({
  gradeDivisions,
  fetchGradeDivisionLoading,
  onEdit,
}) => {
  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Code", dataIndex: "code", key: "code" },
    { title: "Order", dataIndex: "order", key: "order" },
    { title: "Level", dataIndex: "level", key: "level" },
    { title: "Description", dataIndex: "description", key: "description" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => onEdit(record)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <Table
      dataSource={gradeDivisions}
      columns={columns}
      rowKey="id"
      scroll={{ x: 800 }}
      loading={fetchGradeDivisionLoading}
    />
  );
};

export default GradeDivisionTable;
