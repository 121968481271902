import React, { useEffect, useState } from "react";
import { Button, Modal, Select, message, Form } from "antd";
import useFetch from "../../../hooks/useFetch";
import usePost from "../../../hooks/usePost";
import AddTermForm from "../../forms/terms-form/AddTermForm";
import EditTermForm from "../../forms/terms-form/EditTermForm";
import TermsTable from "../../tables/terms-table/TermsTable";
import useFetchTerms from "../../../hooks/useFetchTerms";

const { Option } = Select;

const AcademicTerms = () => {
  // State variables for fetching terms
  const { terms, fetchTermsLoading, fetchTermsError, fetchTerms } =
    useFetchTerms();

  const [termRes, setTermRes] = useState(null);
  const [termLoading, setTermLoading] = useState(false);
  const [termError, setTermError] = useState(null);

  // State variables for setting the current term
  const [currentRes, setCurrentRes] = useState(null);
  const [currentLoading, setCurrentLoading] = useState(false);
  const [currentError, setCurrentError] = useState(null);

  // Modals and form handling
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isCurrentModalVisible, setIsCurrentModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [form] = Form.useForm();
  const [currentTermForm] = Form.useForm();
  const [addForm] = Form.useForm();

  // Add/update term using the usePost hook
  const { post: addOrUpdateTerm, reset: resetTerm } = usePost("api/terms", {
    setLoading: setTermLoading,
    setError: setTermError,
    setData: setTermRes,
  });

  // Set current term using the usePost hook
  const { post: setCurrentTerm, reset: resetCurrentTerm } = usePost(
    "api/terms/current",
    {
      setLoading: setCurrentLoading,
      setError: setCurrentError,
      setData: setCurrentRes,
    }
  );

  useEffect(() => {
    fetchTerms();
  }, []);

  useEffect(() => {
    if (termRes?.message)
      message[termRes?.status || "success"](termRes?.message);

    if (termRes?.status === "success") {
      setIsAddModalVisible(false);
      setIsEditModalVisible(false);
      form.resetFields();
      resetTerm();
      fetchTerms();
    }
  }, [termRes]);

  useEffect(() => {
    console.log("termError >>>>>>>>>>>>>>>>>>>", termError);

    if (termError?.message)
      message[termError?.status || "error"](termError?.message);
    resetTerm();
  }, [termError]);

  const handleEdit = (record) => {
    setSelectedTerm(record);
    setIsEditModalVisible(true);
  };

  const handleEditOk = async () => {
    const values = await form.validateFields();
    await addOrUpdateTerm({
      ...values,
      id: selectedTerm.id,
      startDate: values.startDate.format("YYYY-MM-DD"),
      endDate: values.endDate.format("YYYY-MM-DD"),
    });
  };

  const handleSetCurrentTerm = () => {
    setIsCurrentModalVisible(true);
  };

  const handleSetCurrentOk = async () => {
    try {
      const values = await currentTermForm.validateFields();
      console.log(values);

      await setCurrentTerm({ id: values.termId });
      setIsCurrentModalVisible(false);
      resetCurrentTerm();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentRes?.message)
      message[currentRes?.status || "success"](currentRes?.message);

    if (currentRes?.status === "success") {
      setIsCurrentModalVisible(false);
      resetCurrentTerm();
      fetchTerms();
    }
  }, [currentRes]);

  useEffect(() => {
    console.log("currentError >>>>>>>>>>>>>>>>>>>", currentError);

    if (currentError?.message)
      message[currentError?.status || "error"](currentError?.message);
    resetCurrentTerm();
  }, [currentError]);

  const handleAddTerm = () => {
    setIsAddModalVisible(true);
  };

  const handleAddOk = async () => {
    const values = await addForm.validateFields();
    await addOrUpdateTerm({
      ...values,
      startDate: values.startDate.format("YYYY-MM-DD"),
      endDate: values.endDate.format("YYYY-MM-DD"),
    });
  };

  return (
    <div>
      <h1>Academic Terms</h1>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "end",
          flexWrap: "wrap",
          marginBottom: 10,
          gap: " 0.5rem",
        }}
      >
        <Button
          type="primary"
          onClick={handleSetCurrentTerm}
          loading={currentLoading}
        >
          Set Current Term
        </Button>
        <Button type="primary" onClick={handleAddTerm} loading={termLoading}>
          Add New Term
        </Button>
      </div>

      {/* Use the refactored TermsTable with search and filtering */}
      <TermsTable
        terms={terms}
        loading={fetchTermsLoading}
        onEdit={handleEdit}
      />

      <Modal
        title="Edit Term"
        open={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={() => setIsEditModalVisible(false)}
        confirmLoading={termLoading}
      >
        <EditTermForm form={form} selectedTerm={selectedTerm} />
      </Modal>

      <Modal
        title="Set Current Term"
        open={isCurrentModalVisible}
        onOk={handleSetCurrentOk}
        onCancel={() => setIsCurrentModalVisible(false)}
        confirmLoading={currentLoading}
      >
        <Form form={currentTermForm} layout="vertical">
          <Form.Item
            name="termId"
            rules={[{ required: true, message: "Select a term" }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select a term"
              options={terms.map((term) => ({
                label: term.name,
                value: term.id,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Add New Term"
        open={isAddModalVisible}
        onOk={handleAddOk}
        onCancel={() => setIsAddModalVisible(false)}
        confirmLoading={termLoading}
      >
        <AddTermForm form={addForm} />
      </Modal>
    </div>
  );
};

export default AcademicTerms;
