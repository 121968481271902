import { useState } from "react";
import useFetch from "./useFetch";

const useFetchGradeDivisions = () => {
  const [gradeDivisions, setGradeDivisions] = useState([]);
  const [fetchGradeDivisionLoading, setFetchGradeDivisionLoading] =
    useState(false);
  const [fetchGradeDivisionError, setFetchGradeDivisionError] = useState(null);

  const { get: fetchGradeDivisions } = useFetch("api/gradeDivisions", {
    setLoading: setFetchGradeDivisionLoading,
    setError: setFetchGradeDivisionError,
    setData: setGradeDivisions,
  });

  // Return state and fetch function
  return {
    gradeDivisions,
    fetchGradeDivisionLoading,
    fetchGradeDivisionError,
    fetchGradeDivisions,
  };
};

export default useFetchGradeDivisions;
