import React, { useEffect, useMemo, useState } from "react";
import { grade } from "../../../services/data";
import { PlusOutlined } from "@ant-design/icons";
import {
  Table,
  Button,
  Select,
  Popconfirm,
  message,
  Modal,
  Form,
  Input,
  Card,
  Row,
  Col,
} from "antd";
import "./AssessmentList.css";
import AssessmentForm from "../../forms/assessment-form/AssessmentForm";

import AssessmentListTable from "../../tables/assessment-list-table/AssessmentListTable";
import DynamicTable from "../../tables/DynamicTable";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import usePatch from "../../../hooks/usePatch";
import useDelete from "../../../hooks/useDelete";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";
import { generateColumns } from "../../../utils/helper";

const { Option } = Select;

const AssessmentLists = () => {
  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedGrade, setSelectedGrade] = useState("");
  const [editingAssessment, setEditingAssessment] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [form] = Form.useForm(); // Define form instance

  // Fetch related states
  const [assessments, setAssessments] = useState([]);
  const [assLoading, setAssLoading] = useState(false);
  const [fetchAssError, setFetchAssError] = useState(null);

  // Create related states
  const [assCreateLoading, setAssCreateLoading] = useState(false);
  const [assCreateError, setAssCreateError] = useState(null);
  const [assCreatedRes, setAssCreatedRes] = useState(null);

  // Modify related states
  const [assModifyLoading, setAssModifyLoading] = useState(false);
  const [assModifyError, setAssModifyError] = useState(null);
  const [assModifyRes, setAssModifyRes] = useState(null);

  // Delete related states
  const [assDeleteLoading, setAssDeleteLoading] = useState(false);
  const [assDeleteError, setAssDeleteError] = useState(null);
  const [assDeleteRes, setAssDeleteRes] = useState(null);

  // Fetch Assessments
  const { get: fetchAss } = useFetch("/api/assessmentList", {
    setLoading: setAssLoading,
    setError: setFetchAssError,
    setData: setAssessments,
  });

  console.log("gradeDivisions=============", gradeDivisions);

  // Post Assessment
  const { post: createAssess, reset: resetCreateAssPost } = usePost(
    "/api/assessmentList",
    {
      setLoading: setAssCreateLoading,
      setError: setAssCreateError,
      setData: setAssCreatedRes,
    }
  );

  // Update Assessment
  const { patch: modifyAssess, reset: resetAssModify } = usePatch(
    `/api/assessmentList/${editingAssessment?.id}`,
    {
      setLoading: setAssModifyLoading,
      setError: setAssModifyError,
      setData: setAssModifyRes,
    }
  );

  // Delete Assessment
  const { remove: removeAssess, reset: resetAssRemove } = useDelete(
    `/api/assessmentList`,
    {
      setLoading: setAssDeleteLoading,
      setError: setAssDeleteError,
      setData: setAssDeleteRes,
    }
  );

  // Handle Grade Selection Change
  const handleGradeChange = (val) => {
    setSelectedGrade(val);
  };

  function fetchGradeAssList() {
    if (selectedGrade) fetchAss({ grade: selectedGrade });
  }

  // Fetch assessments when grade changes
  useEffect(() => {
    fetchGradeAssList();
    console.log("assessments", assessments);
  }, [selectedGrade]);

  useEffect(() => {
    fetchGradeDivisions();
  }, []);

  useEffect(() => {
    if (gradeDivisions?.length) setSelectedGrade(gradeDivisions?.[0]?.name);
  }, [gradeDivisions]);

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      createAssess(values);
      setIsAddModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Create failed:", error);
    }
  };

  useEffect(() => {
    if (assCreatedRes?.message)
      message[assCreatedRes?.status || "success"](assCreatedRes?.message);

    if (assCreatedRes?.status === "success") {
      setIsAddModalVisible(false);
      setIsEditModalVisible(false);
      form.resetFields();
      resetCreateAssPost();
      fetchGradeAssList();
    }
  }, [assCreatedRes]);

  useEffect(() => {
    console.log("assCreateError >>>>>>>>>>>>>>>>>>>", assCreateError);

    if (assCreateError?.message)
      message[assCreateError?.status || "error"](assCreateError?.message);
    resetCreateAssPost();
  }, [assCreateError]);

  useEffect(() => {
    if (assModifyRes?.message)
      message[assModifyRes?.status || "success"](assModifyRes?.message);

    if (assModifyRes?.status === "success") {
      setIsEditModalVisible(false);
      form.resetFields();
      resetAssModify();
      fetchGradeAssList();
    }
  }, [assModifyRes]);

  useEffect(() => {
    if (assModifyError?.message)
      message[assModifyError?.status || "error"](assModifyError?.message);
    resetAssModify();
  }, [assModifyError]);

  useEffect(() => {
    if (assDeleteRes?.message)
      message[assDeleteRes?.status || "success"](assDeleteRes?.message);

    if (assDeleteRes?.status === "success") {
      fetchGradeAssList();
      resetAssRemove();
    }
  }, [assDeleteRes]);

  useEffect(() => {
    if (assDeleteError?.message)
      message[assDeleteError?.status || "error"](assDeleteError?.message);
    resetAssRemove();
  }, [assDeleteError]);

  // Handle update action
  const handleUpdate = async () => {
    try {
      await form.validateFields();
      modifyAssess(form.getFieldsValue());
      setIsEditModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  // const tableActions = [
  //   {
  //     label: <EditOutlined />,
  //     onClick: handleEdit,
  //   },
  //   {
  //     label: <DeleteOutlined />,
  //     onClick: (record) => handleDelete(record.id),
  //     confirm: true,
  //     confirmMessage: "Are you sure you want to delete this subject?",
  //     danger: true,
  //   },
  // ];

  // const filteredData = useMemo(() => {
  //   if (!assessments) return [];

  //   return assessments?.filter((item) => {
  //     if (!selectedColumn || !searchText) return true;

  //     return item[selectedColumn]
  //       ?.toString()
  //       .toLowerCase()
  //       .includes(searchText.toLowerCase());
  //   });
  // }, [assessments, searchText, selectedColumn]);

  const customColumns = generateColumns(assessments);
  console.log(customColumns);

  const columnOptions = customColumns.map((col) => ({
    key: col.key,
    value: col.key,
  }));

  console.log(columnOptions);

  return (
    <div id="Assessment">
      <h2>Assessment Setting</h2>
      <Card
        title="Assessment Lists"
        bordered={false}
        style={{ margin: "20px" }}
      >
        <div className="assessment-page">
          {contextHolder}
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col>
              <Input
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{ width: 200 }}
              />
            </Col>
            <Col>
              <Select
                placeholder="Select Column"
                options={columnOptions}
                value={selectedColumn}
                onChange={setSelectedColumn}
                style={{ width: 200 }}
                allowClear
              >
                {/* Dynamic Columns logic here */}
              </Select>
            </Col>
            <Col>
              <Select
                value={selectedGrade}
                onChange={handleGradeChange}
                style={{ marginBottom: 20 }}
                name="grade"
              >
                {gradeDivisions?.map((gra, index) => (
                  <Option key={index} value={gra.name}>
                    {gra.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col flex="auto">
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setIsAddModalVisible(true)}
                >
                  Add Assessment
                </Button>
              </div>
            </Col>
          </Row>

          <AssessmentListTable assData={assessments} assLoading={assLoading} />

          {editingAssessment && (
            <Modal
              title="Edit Assessment"
              open={isEditModalVisible}
              onOk={handleUpdate}
              onCancel={() => setIsEditModalVisible(false)}
            >
              <AssessmentForm
                form={form}
                editingAssessment={editingAssessment}
              />
            </Modal>
          )}

          <Modal
            title="Add Assessment"
            open={isAddModalVisible}
            onOk={handleCreate}
            onCancel={() => setIsAddModalVisible(false)}
          >
            <AssessmentForm form={form} gradeDivisions={gradeDivisions} />
          </Modal>
        </div>
      </Card>
    </div>
  );
};

export default AssessmentLists;
