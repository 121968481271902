import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button } from "antd";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";
import useFetch from "../../../hooks/useFetch";
import { getClassSections } from "../../../utils/helper";

const { Option } = Select;

const TeacherTaskForm = ({ form, fetchTeachers, teachers = [] }) => {
  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();
  const [subjectType, setSubjectType] = useState("");
  const [subjectFilteredWithType, setSubjectFilteredWithType] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");

  // useFetch for subjects
  const [subjects, setSubjects] = useState([]);
  const [subjLoading, setSubjLoading] = useState(false);
  const [subjFetchError, setSubjFetchError] = useState(null);

  const { get: fetchSubj } = useFetch("/api/subject", {
    setLoading: setSubjLoading,
    setError: setSubjFetchError,
    setData: setSubjects,
  });

  useEffect(() => {
    fetchTeachers();
    if (selectedGrade) fetchSubj({ grade: selectedGrade });
  }, []);

  useEffect(() => {
    if (selectedGrade) fetchSubj({ grade: selectedGrade });
  }, [selectedGrade]);

  useEffect(() => {
    fetchGradeDivisions();
  }, []);

  useEffect(() => {
    if (gradeDivisions?.length) setSelectedGrade(gradeDivisions?.[0]?.name);
  }, [gradeDivisions]);

  useEffect(() => {
    console.log(selectedGrade);
  }, [selectedGrade]);

  useEffect(() => {
    form.setFieldsValue({ subject: null });
    setSubjectFilteredWithType(
      subjects.filter((subj) => subj.type == subjectType)
    );
  }, [subjectType]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        label="Teacher"
        name="teacherId"
        rules={[{ required: true, message: "Please input the teacher name!" }]}
      >
        <Select
          showSearch
          placeholder="Select a teacher"
          options={
            teachers &&
            teachers.length &&
            teachers.map((teacher) => ({
              label: teacher.fullName,
              value: teacher.userId,
            }))
          }
          filterOption={(input, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        label="Grade"
        name="grade"
        rules={[{ required: true, message: "Please select a grade" }]}
      >
        <Select
          style={{ marginBottom: 20 }}
          name="grade"
          onChange={(val) => {
            setSelectedGrade(val);
          }}
        >
          {gradeDivisions?.map((gra, index) => (
            <Option key={index} value={gra.name}>
              {gra.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Section"
        name="section"
        rules={[{ required: true, message: "Please select a section!" }]}
      >
        <Select
          options={getClassSections(selectedGrade, gradeDivisions).map(
            (val) => ({
              label: val,
              value: val,
            })
          )}
        />
      </Form.Item>
      <Form.Item
        label="Subject Type"
        name="subjectType"
        rules={[{ required: true, message: "Please select a subject type!" }]}
      >
        <Select onChange={(value) => setSubjectType(value)}>
          <Option value="academic">Academic</Option>
          <Option value="non-academic">Non-academic</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Subject"
        name="subject"
        rules={[{ required: true, message: "Please select a subject!" }]}
      >
        <Select placeholder="Select a subject" loading={subjLoading}>
          {subjectFilteredWithType.map((subject) => (
            <Option key={subject.id} value={subject.name}>
              {subject.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Task Type"
        name="taskType"
        rules={[{ required: true, message: "Please select a task type!" }]}
      >
        <Select>
          <Option value="Standard">Standard</Option>
          <Option value="Home Room">Home Room</Option>
        </Select>
      </Form.Item>
    </Form>
  );
};

export default TeacherTaskForm;
